import React from "react";
import "./PublicationCard.css";
import { Fade } from "react-reveal";

export default function PublicationCard({ pub, theme }) {
  function openPubinNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <div className="pubCard">
      <Fade bottom duration={2000} distance="40px">
        <div
          className="publication-card-div"
          style={{ backgroundColor: theme.text, "--before-color": theme.text }}
        >
          <div
            className="top-section"
            style={{ backgroundColor: theme.highlight }}
          >
            <div
              className="border"
              style={{ backgroundColor: theme.text }}
            ></div>
            <div
              key={pub.id}
              onClick={() => openPubinNewTab(pub.url)}
              style={{ padding: "10px" }}
            >
              <div className="publication-name-div">
                <img
                  className="publication-icon"
                  src={require(`../../assests/images/${pub.icon}`)}
                  alt={pub.name}
                ></img>
                <p className="publication-name" style={{ color: theme.text }}>
                  {pub.name}
                </p>
              </div>
              <p
                className="publication-description"
                style={{ color: theme.text }}
              >
                {pub.description}
              </p>
              <div className="publication-details">
                <p
                  className="publication-creation-date subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  Published on {pub.createdAt.split("T")[0]}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}
