/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Kowsyap's Portfolio",
  description:
    "A dedicated computer engineer with a relentless drive to craft innovative, and scalable end-to-end solutions.",
  og: {
    title: "Kowsyap Pranay Portfolio",
    type: "website",
    url: "http://kowsyap.github.io/",
  },
};

//Home Page
const greeting = {
  title: "Kowsyap Pranay",
  logo_name: "KowsyapPranay",
  // nickname: "pk",
  summary:
    "A dedicated computer engineer with a relentless drive to craft innovative, and scalable end-to-end solutions.",
  resumeLink:
    "https://drive.google.com/drive/folders/1rg5wjFJPFUuuRKdKC9FXavs18AZW0_zv?usp=sharing",
  githubProfile: "https://github.com/kowsyap",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/kowsyap",
    fontAwesomeIcon: "fa-github",
    backgroundColor: "#181717",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/kowsyap-pranay/",
    fontAwesomeIcon: "fa-linkedin-in",
    backgroundColor: "#0077B5",
  },
  {
    name: "Gmail",
    link: "mailto:pranaykowsyap@gmail.com",
    fontAwesomeIcon: "fa-google",
    backgroundColor: "#D14836",
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/kowsyap.pranay/",
    fontAwesomeIcon: "fa-facebook-f",
    backgroundColor: "#1877F2",
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/pk_ledgar/",
    fontAwesomeIcon: "fa-instagram",
    backgroundColor: "#E4405F",
  },
];

const skills = {
  data: [
    {
      title: "VLSI Design",
      fileName: "vlsiImg",
      skills: [
        "⚡ Extensive experience in ASIC design and verification, particularly in memory design and verification using SystemVerilog and UVM",
        "⚡ Proficient in tools like Cadence, Xilinx and methodologies relevant to Full-Custom, ASIC development",
        "⚡ Proficient in designing digital circuits using Verilog & VHDL, including various 8-bit digital circuits and algorithms such as adders, multipliers, and more",
        "⚡ Skilled in optimizing circuit designs for efficiency and performance",
      ],
      softwareSkills: [
        {
          skillName: "Cadence",
          imageSrc: "cadence.svg",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Verilog",
          fontAwesomeClassname: "vscode-icons:file-type-verilog",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "VHDL",
          fontAwesomeClassname: "vscode-icons:file-type-vhdl",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "System Verilog",
          fontAwesomeClassname: "vscode-icons:file-type-systemverilog",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Vivado",
          fontAwesomeClassname: "simple-icons:xilinx",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Programming",
      fileName: "ProgramImg",
      skills: [
        "💻 Proficient in a diverse range of programming languages, including Python, Java, C, C++, C#, JavaScript, and TypeScript",
        "💻 Adept at leveraging these languages to design and implement software solutions for various applications, from web development to embedded systems",
        "💻 Extensive experience in problem-solving and algorithmic thinking, consistently finding innovative and efficient solutions to complex challenges",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "logos-python",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "logos-java",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "devicon:c",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "devicon:cplusplus",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "devicon:csharp",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "logos:javascript",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "logos:typescript-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "logos-php",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "🌐 Proficient in building dynamic and visually appealing user interfaces with a strong command of front-end frameworks such as Angular, React, and Vue",
        "🌐 Skilled in state management with Redux and NgRx, ensuring a seamless and responsive user experience",
        "🌐 Designing and implementing robust application backends using Node.js, Express. Skilled in developing RESTful APIs and handling server-side logic",
        "🌐 Proficient in working with databases, including MongoDB, MySQL, and PostgreSQL, to store and retrieve data efficiently",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "logos:javascript",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "logos:typescript-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "logos:angular-icon",
          style: {
            color: "#dd1b16",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "VueJS",
          fontAwesomeClassname: "logos:vue",
          style: {
            color: "#41B883",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:nodedotjs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "devicon:mysql",
          style: {
            color: "#00758f",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Embedded, AI & IoT",
      fileName: "IoTImg",
      skills: [
        "🤖 Skilled in programming microcontrollers and interfacing them with various sensors and actuators for real-world applications",
        "🤖 Well-versed in IoT technologies, including the development of IoT platforms, real-time monitoring, and data analysis",
        "🤖 Experienced in creating IoT products tailored for industrial applications, with a focus on enhancing efficiency and automation",
        "🤖 Skilled in machine learning, leveraging data-driven insights for enhanced IoT solutions and automation",
      ],
      softwareSkills: [
        {
          skillName: "Embedded C",
          fontAwesomeClassname: "devicon:embeddedc",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Raspberry Pi",
          fontAwesomeClassname: "skill-icons:raspberrypi-dark",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Arduino",
          fontAwesomeClassname: "skill-icons:arduino",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "LabView",
          fontAwesomeClassname: "devicon:labview-wordmark",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Machine Learning",
          fontAwesomeClassname: "carbon:machine-learning-model",
          style: {
            color: "rgb(10, 36, 114)",
          },
        },
      ],
    },
    {
      title: "Design & Graphics",
      fileName: "DesignImg",
      skills: [
        "🎨 Proficient in Adobe Photoshop, enabling creative image editing and manipulation",
        "🎨 Adept at the art of sketching, whether on paper or digitally, to breathe life into creative visions with meticulous attention to detail",
        "🎨 Proficient in AutoCAD, specializing in precision drafting and design",
      ],
      softwareSkills: [
        {
          skillName: "Adobe Photoshop",
          fontAwesomeClassname: "logos:adobe-photoshop",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "AutoCAD",
          fontAwesomeClassname: "skill-icons:autocad-light",
          style: {
            color: "#F24E1E",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "George Mason University",
      subtitle: "Master’s in Computer Engineering",
      logo_path: "gmu.png",
      alt_name: "GMU",
      duration: "2024 - PRESENT",
      descriptions: [
        "🎓 Pursuing Master’s in Computer Engineering with a focus on VLSI domain.",
      ],
      website_link: "http://gmu.edu",
    },
    {
      title: "Indian Institute of Information Technology Kurnool",
      subtitle: "B.Tech. in Electronics & Communication Engineering",
      logo_path: "iiitk_logo.png",
      alt_name: "IIITDM Kurnool",
      duration: "2017 - 2021",
      descriptions: [
        "🎓 I'm an electronics enthusiast, well-versed in <b>VLSI</b> and <b>Embedded Systems</b>, complemented by proficiency in <b>computer science</b> areas like Data Structures & Algorithms and Operating Systems.",
        "🎓 My hands-on involvement in VLSI <b>research</b> led to the publication of an <b>IEEE</b> paper, showcasing my dedication to advancing electronics knowledge.",
        "🎓 With a strong foundation in both electronics and computer science, I bridge the gap between hardware and software, bringing a holistic approach to my studies and projects.",
        "🎓  Achieving a strong CGPA of <b>8.77</b> underscores my commitment to academic excellence and continuous learning.",
      ],
      website_link: "http://iiitk.ac.in",
    },
    {
      title: "Ratnam Junior College",
      subtitle: "Class XII | MPC",
      logo_path: "ratnam.png",
      alt_name: "Ratnam Junior College",
      duration: "2015 - 2017",
      descriptions: [
        "🎓 Acquired a solid foundation in mathematics, physics, and chemistry, progressing from basic to advanced levels of understanding in these core subjects.",
        "🎓 In addition to excelling in the sciences, I honed my language skills, achieving proficiency in both English and Sanskrit, broadening my linguistic horizons.",
        "🎓 My dedication to academics culminated in an impressive academic achievement, as I scored <b>97.8%</b>, reflecting my commitment to excellence in my studies.",
      ],
    },
    {
      title: "Little Angels Public School",
      subtitle: "Class X | CBSE",
      logo_path: "laps.png",
      alt_name: "LAPS",
      duration: "2014 - 2015",
      descriptions: [
        "🎓 Achieved a perfect GPA of <b>10</b> and was elected as a <b>school leader</b>.",
        "🎓 Excelled in quizzes and science fairs while maintaining a strong academic record.",
        "🎓 Held the position of a school leader, showcasing leadership qualities and active participation in extracurricular activities.",
      ],
      website_link: "https://lapschool.org/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/5F97DDYPU8BR",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Azure Fundamentals",
      subtitle: "- Microsoft",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://www.credly.com/badges/6851301c-6ef9-4a85-b0e5-5fa042b697c9?source=linked_in_profile",
      alt_name: "Microsoft",
      color_code: "#1F70C199",
    },
    {
      title: "Azure AI Fundamentals",
      subtitle: "- Microsoft",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://learn.microsoft.com/en-us/users/kowsyappranay-6112/credentials/450583BB41C2A304",
      alt_name: "Microsoft",
      color_code: "#1F70C199",
    },
    {
      title: "Azure Data Fundamentals",
      subtitle: "- Microsoft",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://learn.microsoft.com/en-us/users/kowsyappranay-6112/credentials/D65BD867CEFF4333",
      alt_name: "Microsoft",
      color_code: "#1F70C199",
    },
    {
      title: "Angular",
      subtitle: "- J.K.Muppala",
      logo_path: "hnkng.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/TBE3TYMKGNDT",
      alt_name: "Coursera",
      color_code: "#fff",
    },
    {
      title: "React",
      subtitle: "- J.K.Muppala",
      logo_path: "hnkng.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/TZ3TE5K8R3S3",
      alt_name: "Coursera",
      color_code: "#fff",
    },
    {
      title: "Web Applications in PHP",
      subtitle: "- Charles Severance",
      logo_path: "uom.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/SSLGRQ9LPV92",
      alt_name: "Coursera",
      color_code: "#00274c",
    },
    {
      title: "HDL for FPGA Design",
      subtitle: "- Timothy Scherr",
      logo_path: "uoc.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/SWBBLY4FCDYQ",
      alt_name: "Coursera",
      color_code: "#000000",
    },
    {
      title: "FPGA Design for Embedded Systems",
      subtitle: "- Timothy Scherr",
      logo_path: "uoc.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/96JF6XLRHW9K",
      alt_name: "Coursera",
      color_code: "#000000",
    },
    {
      title: "Programming the IoT",
      subtitle: "- Ian Harris",
      logo_path: "uci.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/certificate/5W8TA2QGLWJ5",
      alt_name: "Coursera",
      color_code: "#fff",
    },
    {
      title: "IoT Skilling",
      subtitle: "- IoTIot.in",
      logo_path: "iotiot.png",
      certificate_link:
        "https://blockchain.iotiot.in/docs/5efb458d8ea629383d78fecb",
      alt_name: "IoTioT.in",
      color_code: "#9bd949",
    },
    {
      title: "IoT Project Internship",
      subtitle: "- SmartBridge",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://smartinternz.com/internships/certificates/b7892fb3c2f009c65f686f6355c895b5",
      alt_name: "codetantra",
      color_code: "#fff",
    },
    {
      title: "C Programming",
      subtitle: "- CodeTantra",
      logo_path: "codetantra.jpg",
      certificate_link:
        "https://iiitdmkl.codetantra.com/cert/certificate.jsp?certId=CT481-r9oBLbo-cb",
      alt_name: "codetantra",
      color_code: "#f7f7f7",
    },
    {
      title: "Python Programming",
      subtitle: "- CodeTantra",
      logo_path: "codetantra.jpg",
      certificate_link:
        "https://iiitdmkl.codetantra.com/cert/certificate.jsp?certId=CT481-r9oBLbo-cc",
      alt_name: "codetantra",
      color_code: "#f7f7f7",
    },
    {
      title: "C++ Programming",
      subtitle: "- John Purcell",
      logo_path: "udemy.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-14e158d3-6c0b-430f-8b57-5b0c5f6cdd97/",
      alt_name: "codetantra",
      color_code: "#ffb89f",
    },
    {
      title: "Cloud Computing from Scratch",
      subtitle: "- Eduonix",
      logo_path: "eduonix.png",
      certificate_link: "https://www.eduonix.com/certificate/ab3f9a7767",
      alt_name: "Eduonix",
      color_code: "#fff",
    },
  ],
};

// Experience Page
const experience = {
  title: "Career Odyssey",
  subtitle: "Work, Internship and Volunteer Engagements",
  description:
    "My experiences page provides a comprehensive overview of my work, internships, and volunteer engagements, highlighting my professional journey and contributions.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Graduate Teaching Assistant",
          company: "George Mason University",
          company_url: "https://www.gmu.edu/",
          logo_path: "gmu.png",
          duration: "JAN 2025 - Present",
          location: "Virginia, USA",
          description:
            "<p>Involved in coursework focused on </strong>computer arithmetic, memory organization, control design, VHDL-based digital system modeling, FPGA design flow</strong>, and <strong>hardware implementation</strong>.</p>",
          color: "#0879bf",
        },
        {
          title: "Power Programmer",
          company: "Infosys",
          company_url: "https://www.infosys.com/",
          logo_path: "infosys-logo-png.png",
          duration: "Nov 2023 - JULY 2024",
          location: "Hyderabad, Telangana",
          description:
            "<p>As a <strong>Specialist Programmer</strong> at <strong>Infosys</strong>, I developed dynamic UIs using <strong>Angular</strong>, incorporating <strong>RxJS</strong> for efficient asynchronous data handling and creating reusable components. I built and maintained RESTful APIs and <strong>GraphQL</strong> endpoints with <strong>Node.js</strong>, ensuring robust and scalable back-end services. Additionally, I designed optimized <strong>MongoDB</strong> schemas and queries, leveraging indexing and aggregation to enhance performance. I also managed the deployment and operation of applications on <strong>Azure</strong>, ensuring reliability and scalability.</p>",
          color: "#0879bf",
        },
        {
          title: "Software Engineer",
          company: "MSR Cosmos LLP",
          company_url: "https://www.msrcosmos.com/",
          logo_path: "MSRcososGroup-logo.svg",
          duration: "May 2021 - Nov 2023",
          location: "Hyderabad, Telangana",
          description:
            "<p> During my tenure as a <strong>Software Engineer</strong> at <strong>MSR Cosmos LLC</strong>, I've amassed over <strong>2.5 years</strong> of <strong>web development</strong> experience marked by significant achievements. I've led <strong>full-stack</strong> web projects from conception to deployment, showcasing proficiency in technologies like <strong>Node.js, JavaScript, Angular, and React</strong>, facilitating seamless server-to-database interactions. My expertise extends to database technologies, encompassing <strong>SQL, NoSQL, and database management</strong>. I've successfully delivered Model-View-Controller (MVC) architecture-based Single Page Applications, consistently exceeding performance benchmarks. My hands-on experience also includes application deployment on <strong>Azure</strong> and a solid understanding of <strong>CI/CD pipelines</strong>. Collaboratively, I've engaged with clients to define and deliver precise <strong>solution requirements</strong>, ensuring project success.</p>",
          color: "#0879bf",
        },
        {
          title: "Subject Matter Expert",
          company: "Chegg Inc.",
          company_url: "https://www.chegg.com/",
          logo_path: "chegg.svg",
          duration: "Sept 2021 - Present",
          location: "Remote",
          description:
            "<p>As a dedicated <strong>Subject Matter Expert</strong> at <strong>Chegg</strong>, I leveraged my expertise in <strong>Electronics</strong> and <strong>Computers</strong> to assist and empower students in their academic pursuits for over 2 years. I provided valuable guidance, solutions, and support, contributing to their educational success.</p>",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Internet of Things Intern",
          company: "IotIot.in",
          company_url: "https://iotiot.in/iotwalloffamemay20/",
          logo_path: "iotiot.png",
          duration: "June 2020 - Jan 2021",
          location: "Pune, Maharashtra",
          description: `Worked as an intern and a <strong>Project Lead</strong> in developing industry-ready IoT products. During the internship, I developed a project titled <strong>Transformer Anomaly Detection</strong>. This project helps in real-time monitoring the voltage waveform, ambient temperature, and hotspot temperature of the transformer and detect if any anomalies occur using <strong>Machine Learning</strong> Algorithms.`,
          color: "#000000",
        },
        {
          title: "SoC DV Intern",
          company: "SION Semiconductors Pvt Ltd.",
          company_url: "https://www.sionsemi.com/",
          logo_path: "sion.jpg",
          duration: "May 2020 - Nov 2020",
          location: "Bangalore, Karnataka",
          description:
            "<p>During my six-month internship focused on <strong>System-on-Chip (SoC) Design and Verification</strong>, I had the opportunity to delve into various advanced concepts, including <strong>FSM Design</strong>, <strong>Low Power Techniques</strong>, <strong>RTL Design Strategies</strong>, <strong>Delay Concepts</strong>, <strong>Assertions</strong>, <strong>Functional Coverage</strong>, <strong>Threads</strong>, <strong>SystemVerilog</strong>, and <strong>UVM verification environments</strong>. Additionally, I gained valuable insights into <strong>Interface Protocol Design</strong> and <strong>SoC Architectures</strong>. As part of my internship, I successfully completed a significant project titled <strong>Memory Level Verification of Dual Ported RAM using SV and UVM environments</strong>, demonstrating my practical application of these concepts.</p>",
          color: "#ee3c26",
        },
        {
          title: "Summer Intern",
          company: "TheSmartBridge",
          company_url: "https://www.thesmartbridge.com/",
          logo_path: "smartbridge.png",
          duration: "May 2020 - June 2020",
          location: "Hyderabad, Telangana",
          description:
            "<p>Throughout the internship, I designed a project titled <strong>IoT-Based Smart Agriculture System</strong>. This innovative system empowers farmers to remotely monitor and manage their farms using a web-based application created with <strong>Node-RED</strong>. The system leverages the <strong>IBM IoT Watson cloud platform</strong> as its backend, enabling seamless and efficient farm management.</p>",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteer Engagements",
      experiences: [
        {
          title: "Technical Coordinator",
          company: "Electrophiles IIITDM Kurnool",
          company_url: "https://iiitk.ac.in/",
          logo_path: "iiitk_logo.png",
          duration: "July 2019 - May 2021",
          location: "Kurnool, Andhra Pradesh",
          description:
            "As the Technical Coordinator for the college's electronics club, Electrophiles, I actively contributed in fostering technical excellence, organizing events, and promoting collaborative learning within the club's vibrant community.",
          color: "#181717",
        },
        {
          title: "Publicity and Sponsorship Coordinator",
          company: "Solasta IIITDM Kurnool",
          company_url: "https://www.instagram.com/iiitk.solasta/",
          logo_path: "solasta.jpg",
          duration: "Jan 2019 - April 2019",
          location: "Kurnool, Andhra Pradesh",
          description:
            "As the Publicity and Sponsorship Coordinator for SOLASTA, I was instrumental in promoting and securing sponsorships for the techno-cultural fest, ensuring its success through effective outreach and partnership building.",
          color: "#181717",
        },
        {
          title: "School People Leader",
          company: "Little Angels Public School",
          company_url: "https://lapschool.org/",
          logo_path: "laps.png",
          duration: "July 2014 - March 2015",
          location: "Gudur, Andhra Pradesh",
          description:
            "As the School People Leader, I was entrusted with the responsibility by my peers, having secured a significant majority of 310 votes. In this role, I effectively represented and led my fellow students, fostering a positive school environment and addressing their needs.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Endeavors",
  description:
    "An exhibition of a wide array of projects, encompassing my work and academic experiences in Web Development, IoT, Machine Learning, and VLSI.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "A record of my research achievements and contributions through IEEE publications.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "1",
      name:
        "Correctness of Synthesis for Tree based Decomposition Algorithm in Semiconductor Memory Designs with Larger Decoders",
      createdAt: "14, JAN 2022",
      icon: "ieee.gif",
      description:
        "This project proves that the tree-based decomposition algorithm is effective in designing larger decoders for semiconductor memory design using a tree of smaller decoders for a particular CMOS technology library. The experimental result shows that the algorithm helps in achieving an efficient semiconductor memory design made of larger decoders with less delay. For example, 64x64 SRAM memory design using the proposed decoder achieve an improvement of 14.5% in delay compared with a design using 1-to-2 decoder tree for 180-nm technology.",
      url: "https://ieeexplore.ieee.org/document/9672450",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Connect",
    profile_image_path: "pk.jpg",
    description:
      "I will be accessible for assistance through the following social media channels and commit to responding within a 24-hour timeframe.",
  },
  addressSection: {
    title: "Address",
    subtitle: "9822 Main St, Fairfax, VA, USA - 22031",
    locality: "Gudur",
    country: "IN",
    region: "Andhra Pradesh",
    postalCode: "524101",
    streetAddress: "Ashok Nagar",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/XXwyHmzs3LbSCwBy9",
    isMap: false,
    embed_map_link: "",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
