import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import ExperienceAccordion from "../../containers/experienceAccordion/ExperienceAccordion.js";
import "./Experience.css";
import { experience } from "../../portfolio.js";
import { Fade } from "react-reveal";
import ExperienceImg from "./ExperienceImg";
import apiService from "../../shared/services/api-services";
import { RingLoader } from "react-spinners";

class Experience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      error: null,
    };
  }
  async componentDidMount() {
    const collection = "experience";
    const endpoint = "find";
    try {
      const response = await apiService(collection, endpoint);
      this.setState({
        data: response.data.sort((a, b) => a.id - b.id),
        loading: false,
      });
    } catch (error) {
      this.setState({
        data: experience["sections"],
        error: error.message,
        loading: false,
      });
    }
  }
  render() {
    const theme = this.props.theme;
    return (
      <div className="experience-main">
        <Header theme={theme} callBack={this.props.callBack} />
        <div className="basic-experience">
          <Fade bottom duration={2000} distance="40px">
            <div className="experience-heading-div">
              <div className="experience-heading-img-div">
                <ExperienceImg theme={theme} />
              </div>
              <div className="experience-heading-text-div">
                <h1
                  className="experience-heading-text"
                  style={{ color: theme.text }}
                >
                  {experience.title}
                </h1>
                <h3
                  className="experience-heading-sub-text"
                  style={{ color: theme.text }}
                >
                  {experience["subtitle"]}
                </h3>
                <p
                  className="experience-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {experience["description"]}
                </p>
              </div>
            </div>
          </Fade>
        </div>
        {this.state.loading ? (
          <RingLoader size={60} color="#3498db" />
        ) : (
          <ExperienceAccordion sections={this.state.data} theme={theme} />
        )}
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Experience;
