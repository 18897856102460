import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import AddressImg from "./AddressImg";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { greeting, contactPageData } from "../../portfolio.js";
import emailjs from "emailjs-com";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

emailjs.init("RiOoBtqpGfrI1OgKF");

const ContactData = contactPageData.contactSection;
const addressSection = contactPageData.addressSection;
const phoneSection = contactPageData.phoneSection;

class Contact extends Component {
  addressData = localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile")).addressSection
    : addressSection;
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      submitted: false,
      valid: {
        name: true,
        email: true,
        message: true,
      },
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      valid: { ...this.state.valid, [name]: true },
    });
  };

  sendEmail = (params) => {
    const templateParams = {
      name: params.name || "",
      email: params.email || "",
      message: params.message || "",
    };

    emailjs
      .send("service_oxgdier", "portfolio_123", templateParams)
      .then((response) => {
        NotificationManager.success("Message sent successfully!");
      })
      .catch((error) => {
        NotificationManager.error("Failed to send the message!");
      });
  };

  render() {
    const theme = this.props.theme;
    const { submitted, valid } = this.state;
    const submit = (e) => {
      e.preventDefault();
      const { name, email, message } = this.state;
      const isValid = {
        name: !!name.trim(),
        email: /\S+@\S+\.\S+/.test(email),
        message: !!message.trim(),
      };

      this.setState({ submitted: true, valid: isValid }, () => {
        if (Object.values(isValid).every((field) => field)) {
          this.sendEmail(this.state);
        }
      });
    };
    return (
      <div className="contact-main">
        <Header theme={theme} callBack={this.props.callBack} />
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-img-div">
                <img
                  className="round"
                  src={require(`../../assests/images/${ContactData["profile_image_path"]}`)}
                  alt=""
                />
              </div>
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {ContactData["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {ContactData["description"]}
                </p>
                <SocialMedia theme={theme} />
                <div className="resume-btn-div">
                  <Button
                    text="My Resume"
                    newTab={true}
                    href={greeting.resumeLink}
                    theme={theme}
                    type={2}
                  />
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom duration={1000} distance="40px">
            <div className="address-heading-div">
              <div className="contact-heading-img-div">
                <AddressImg theme={theme} />
              </div>
              <div className="address-heading-text-div">
                <h1
                  className="address-heading-text"
                  style={{ color: theme.text }}
                >
                  {this.addressData["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {this.addressData["subtitle"]}
                </p>
                <h1
                  className="address-heading-text"
                  style={{ color: theme.text }}
                >
                  {phoneSection["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {phoneSection["subtitle"]}
                </p>
                <div className="address-btn-div">
                  {!this.addressData.isMap ? (
                    <Button
                      text="Locate Me"
                      newTab={true}
                      href={this.addressData.location_map_link}
                      theme={theme}
                      type={2}
                    />
                  ) : (
                    <iframe
                      title="map"
                      src={this.addressData.embed_map_link}
                      width="400"
                      height="350"
                      style={{ border: 0, borderRadius: "10px" }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  )}
                </div>
                <div className="contact-form" style={{ marginTop: "50px" }}>
                  <form className="form" style={{ borderColor: theme.text }}>
                    <div className="title" style={{ color: theme.text }}>
                      Get in Touch
                    </div>
                    <input
                      type="text"
                      placeholder="Your name"
                      style={{ borderColor: theme.text, color: theme.text2 }}
                      className={`${
                        submitted && !valid.name ? "has-error input" : "input"
                      }`}
                      id="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                      required
                    />
                    <input
                      placeholder="Your email"
                      style={{ borderColor: theme.text, color: theme.text2 }}
                      className={`${
                        submitted && !valid.email ? "has-error input" : "input"
                      }`}
                      type="email"
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      required
                    />
                    <textarea
                      placeholder="Your message"
                      style={{ borderColor: theme.text, color: theme.text2 }}
                      className={`${
                        submitted && !valid.message ? "has-error" : ""
                      }`}
                      id="message"
                      name="message"
                      value={this.state.message}
                      onChange={this.handleInputChange}
                      required
                    ></textarea>
                    <button
                      onClick={submit}
                      className="m-auto main-button"
                      style={{
                        "--text": theme.text,
                        color: theme.body,
                        backgroundColor: theme.text,
                        border: `solid 1px ${theme.text}`,
                      }}
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
        <NotificationContainer />
      </div>
    );
  }
}

export default Contact;
