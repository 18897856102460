import React from "react";
import "./Button.css";

const onMouseEnter = (event, color, bgColor, type) => {
  const el = event.target;
  el.style.color = color;
  el.style.backgroundColor = bgColor;
};

const onMouseOut = (event, color, bgColor, type) => {
  const el = event.target;
  el.style.color = color;
  el.style.backgroundColor = bgColor;
};

export default function Button({ text, className, href, newTab, theme, type }) {
  return (
    <div className={className}>
      {type == 2 && (
        <a
          className="main-button main-button2"
          href={href}
          target={newTab && "_blank"}
          style={{
            "--txt": theme.text,
            "--bgclr": theme.body,
            color: theme.text,
            backgroundColor: theme.body,
            border: `solid 1px ${theme.text}`,
          }}
        >
          <span className="circle1"></span>
          <span className="circle2"></span>
          <span className="circle3"></span>
          <span className="circle4"></span>
          <span className="circle5"></span>
          <span className="text">{text}</span>
        </a>
      )}
      {type != 2 && (
        <a
          className="main-button"
          href={href}
          target={newTab && "_blank"}
          style={{
            color: theme.body,
            backgroundColor: theme.text,
            border: `solid 1px ${theme.text}`,
          }}
          onMouseEnter={(event) => onMouseEnter(event, theme.text, theme.body)}
          onMouseOut={(event) => onMouseOut(event, theme.body, theme.text)}
        >
          {text}
        </a>
      )}
    </div>
  );
}
