import React, { useEffect, useState } from "react";
import "./SocialMedia.css";
import { socialMediaLinks } from "../../portfolio";
import styled from "styled-components";
import apiService from "../../shared/services/api-services";
import { RingLoader } from "react-spinners";

const IconWrapper = styled.span`
  i {
    background-color: ${(props) => props.backgroundColor};
  }
  &:hover i {
    background-color: ${({ theme }) => theme.text};
    transition: 0.3s ease-in;
  }
`;

const SocialMedia = (props) => {
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const collection = "socialMedia";
    const endpoint = "find";
    try {
      const response = await apiService(collection, endpoint);
      setMedia(response.data);
      setLoading(false);
    } catch (error) {
      setMedia(socialMediaLinks);
      setLoading(false);
    }
  };
  return (
    <div className="social-media-div">
      {loading ? (
        <RingLoader size={60} color="#3498db" />
      ) : (
        media.map((media, i) => {
          return (
            <a
              href={media.link}
              className={`icon-button`}
              target="_blank"
              rel="noopener noreferrer"
              key={i}
            >
              <IconWrapper {...media} {...props}>
                <i className={`fab ${media.fontAwesomeIcon}`}></i>
              </IconWrapper>
              {/* <span></span> */}
            </a>
          );
        })
      )}
    </div>
  );
};

export default SocialMedia;
