import axios from "axios";

const database = "portfolio";
const dataSource = "kowsyap";
const dbPassKey = "Qwerty@123";
const dbUser = "pranaykowsyap";
const apiKey =
  "785rZk6cESFRAcMUGHwumv7Q9XQpFJQi8hwY2VFooQX9MZ7O0ozdh0FTXOdHucFx";

const apiService = async (collection, endpoint, extra = {}) => {
  try {
    const payload = {
      collectionName: collection,
      endpoint: endpoint,
      extra: extra,
    };

    const response = await axios.post(
      `https://anime-nest-server.onrender.com/portfolio/api/fetch`,
      payload,
      {
        timeout: 10000,
      }
    );
    return response.data;
  } catch (error) {
    if (error.code === "ECONNABORTED") {
      console.error("Error: Request timed out after 10 seconds");
    } else {
      console.error(
        "Error in API call:",
        error.response ? error.response.data : error.message
      );
    }
    throw error;
  }
};

export default apiService;
