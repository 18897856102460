import React, { Component } from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";
import Zoom from "react-reveal/Zoom";
import Flip from "react-reveal/Flip";
import ProgramImg from "./ProgramImg";
import CloudInfraImg from "./CloudInfraImg";
import IoTImg from "./IoTImg";
import FullStack from "./FullStack";
import Design from "./Design";
import VlsiImg from "./VlsiImg";
import apiService from "../../shared/services/api-services";
import { RingLoader } from "react-spinners";

function GetSkillSvg(props) {
  if (props.fileName === "ProgramImg")
    return <ProgramImg theme={props.theme} />;
  else if (props.fileName === "FullStackImg")
    return <FullStack theme={props.theme} />;
  else if (props.fileName === "CloudInfraImg")
    return <CloudInfraImg theme={props.theme} />;
  else if (props.fileName === "IoTImg") return <IoTImg theme={props.theme} />;
  else if (props.fileName === "vlsiImg") return <VlsiImg theme={props.theme} />;
  return <Design theme={props.theme} />;
}

class SkillSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      error: null,
    };
  }
  async componentDidMount() {
    const collection = "skills";
    const endpoint = "find";
    try {
      const response = await apiService(collection, endpoint);
      this.setState({ data: response.data, loading: false });
    } catch (error) {
      this.setState({
        data: skills.data,
        error: error.message,
        loading: false,
      });
    }
  }

  render() {
    const theme = this.props.theme;
    return (
      <div>
        {this.state.loading ? (
          <RingLoader size={60} color="#3498db" />
        ) : (
          this.state.data.map((skill, i) => {
            return (
              <div className="skills-main-div" key={i}>
                <Fade left duration={2000}>
                  <div className="skills-image-div">
                    <GetSkillSvg fileName={skill.fileName} theme={theme} />
                  </div>
                </Fade>

                <div className="skills-text-div">
                  <Zoom left cascade>
                    <h1
                      className="skills-heading"
                      style={{ color: theme.text }}
                    >
                      {skill.title}
                    </h1>
                  </Zoom>
                  <Flip left>
                    <SoftwareSkill logos={skill.softwareSkills} />
                  </Flip>
                  <Flip left cascade>
                    <div>
                      {skill.skills.map((skillSentence, i) => {
                        return (
                          <p
                            className="subTitle skills-text"
                            key={i}
                            style={{ color: theme.secondaryText }}
                          >
                            {skillSentence}
                          </p>
                        );
                      })}
                    </div>
                  </Flip>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  }
}

export default SkillSection;
