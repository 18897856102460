import React, { Component } from "react";
import "./Certifications.css";
import { Fade } from "react-reveal";
import { certifications } from "../../portfolio";
import CertificationCard from "../../components/certificationCard/CertificationCard";
import apiService from "../../shared/services/api-services";
import { RingLoader } from "react-spinners";

class Certifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      error: null,
    };
  }
  async componentDidMount() {
    const collection = "certifications";
    const endpoint = "find";
    try {
      const response = await apiService(collection, endpoint);
      this.setState({ data: response.data, loading: false });
    } catch (error) {
      this.setState({
        data: certifications.certifications,
        error: error.message,
        loading: false,
      });
    }
  }

  render() {
    const theme = this.props.theme;
    return (
      <div className="main" id="certs">
        <div className="certs-header-div">
          <Fade bottom duration={2000} distance="20px">
            <h1 className="certs-header" style={{ color: theme.text }}>
              Certifications
            </h1>
          </Fade>
        </div>
        <div className="certs-body-div">
          {this.state.loading ? (
            <RingLoader size={60} color="#3498db" />
          ) : (
            this.state.data.map((cert, i) => {
              return (
                <CertificationCard certificate={cert} theme={theme} key={i} />
              );
            })
          )}
        </div>
      </div>
    );
  }
}

export default Certifications;
